import sun from "../../assets/banners/sunglasses.png";
import lens from "../../assets/banners/lens.png";
import reg from "../../assets/banners/regular.png";
import acces from "../../assets/banners/accessories.png";

const CategoriesImages = [
    // 1st Column
    sun,
    // 2nd Column
    lens,
    // 3rd Column
    acces,
    // 4th Column
    reg
];

export default CategoriesImages;