export const ALL_CATEGORIES_REQUEST = "ALL_CATEGORIES_REQUEST";
export const ALL_CATEGORIES_SUCCESS = "ALL_CATEGORIES_SUCCESS";
export const ALL_CATEGORIES_FAIL = "ALL_CATEGORIES_FAIL";

export const NEW_CATEGORIES_REQUEST = "NEW_CATEGORIES_REQUEST";
export const NEW_CATEGORIES_SUCCESS = "NEW_CATEGORIES_SUCCESS";
export const NEW_CATEGORIES_FAIL = "NEW_CATEGORIES_FAIL";
export const NEW_CATEGORIES_RESET = "NEW_CATEGORIES_RESET";

export const UPDATE_CATEGORIES_REQUEST = "NEW_CATEGORIES_REQUEST";
export const UPDATE_CATEGORIES_SUCCESS = "NEW_CATEGORIES_SUCCESS";
export const UPDATE_CATEGORIES_FAIL = "NEW_CATEGORIES_FAIL";
export const UPDATE_CATEGORIES_RESET = "UPDATE_CATEGORIES_RESET";

export const DELETE_CATEGORIES_REQUEST = "NEW_CATEGORIES_REQUEST";
export const DELETE_CATEGORIES_SUCCESS = "NEW_CATEGORIES_SUCCESS";
export const DELETE_CATEGORIES_FAIL = "NEW_CATEGORIES_FAIL";
export const DELETE_CATEGORIES_RESET = "NEW_CATEGORIES_RESET";

export const CLEAR_ERRORS = "CLEAR_ERRORS";